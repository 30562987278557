@import "../global";

// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui";

@import 'login';
@import 'dashboard';
@import 'registration';
@import 'message';
@import 'course';
@import 'header';


// Fix mobile footer
.app-footer {
    flex: 0 0;
    padding-top: map-get($spacers, 3);
    padding-bottom: map-get($spacers, 3);
}