//MESSAGE

.message {
	background-image: url('../img/homepage/bg-blue.png');
	background-repeat: no-repeat;
    background-size: cover;

	.first {
		min-height: 80vh;

		.logo{
			img{
				border-radius: 5px;
    			overflow: hidden;
			}
		}

		.rocket{
			width: fit-content;
		    position: absolute;
		    bottom: 10px;
		    opacity: 50%;
		    right: 30%;
		}

		.content{
			margin: 2em;
		    background: rgba(111,205,206,0.1);
		    padding: 1em;
		    border-radius: 5px;
		    font-size: 1.1em;
		    z-index: 10;
		}
	}
}