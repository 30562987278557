// LOGIN & REGISTER PAGE

$soft-blue: #E2F6FD;
$green: #ECFF00;
$green-soft: #F5FF70;
$green-dark: #D6E701;

$red : #EA3525;

$login-black: #1E1E1E;


.login{
    background-color: $soft-blue;
    height: fit-content;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    font-family: 'Jost', Verdana, sans-serif;   
    font-size: 13px;

    .form-group{
        overflow: hidden;

        label{
            padding: 5px 10px;
            font-size: 12px;
            letter-spacing: 1px;
            color: #5B5B5B;
            margin: 0px;
        }

        input.form-control{
            border-radius: 0px;
            border: none;
            background-color: #F6F6F6;
        }
    }

    .first{
        padding: 0px;
        display: flex;

        .card{
            margin: 0px !important;
            width: 100%;
            border: none;

            .title{
                padding-top: 3em;
                h4{
                    color: $red;
                    font-weight: bold;
                    font-size: 2em;
                }

                h5{

                }
            }

            .cont-text{
                padding: 1em 0px;
                color: #5B5B5B;
            }

            .cont-login{
                display: flex;
                flex-direction: column;

                .text{
                    width: 100px;
                }

                .option{
                    width: 30px;
                    margin-right: 10px;

                    img{
                        width: 100%;
                    }
                }

                a{
                    cursor: pointer;
                    color: #23282c;
                    background-color: #f0f3f5;
                    border-color: #f0f3f5;
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 2px;
                    border-radius: 5px;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1px;
                }
            }

            .cta{
                button{
                    min-width: 110px;
                }

                .btn-primary{
                    background-color: $login-black;
                    color: #FFFFFF;
                    border-color: $login-black;
                }

                .btn-outline{
                    background-color: #FFFFFF;
                    border-color: $login-black;   
                    color: $login-black;
                    
                }
            }

            .forgot{
                margin-bottom: 1em;

                a{
                    font-size: 11px;
                    color: $login-black;
                }
            }
        }
    }

    .second{
        padding: 0;
        position: relative;
        background-color: $soft-blue;

        img{
            width: 100%;
        }

        .logo{
            border-radius: 10px;
            position: absolute;
            overflow: hidden;
            width: 30%;
            right: 3%;
            top: 3%;
            
        }

    }
}