// REGISTRATION
$ilq-turqoise : #6FCDCE;
$soft-blue: #E2F6FD;
$green: #ECFF00;
$green-soft: #F5FF70;
$green-dark: #D6E701;

$red : #EA3525;

$login-black: #1E1E1E;

.registration{

    .cursor-pointer{
        cursor: pointer;
    }

    form{
        padding: 1em;
    }

    .btn-group{

        label{
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            height: 45px;
            background-color: white;
            transition: 0.5s;
            color: gray;
            border-radius: 0.25rem;
            background-color: #f8f9fa;
            margin-right: 2px;
            border-style: solid;
            border-color: #f8f9fa;
            cursor: pointer;
        }

        input[type^="radio"] {
            display: none;
        }

        //radioSubscription
        input[name^="radioSubscription"]:checked+label, 
        input[name^="radioRole"]:checked+label{
            background-color: #2c2c2c;
            color: white;
            border-color: #2c2c2c;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
        }
    }

    #email-details{
        display: none;
    }

    #proceed-options{
        display: block;
    }

    a{
        cursor: pointer;
    }

    .nav-tabs{
        border-bottom: 1px solid $login-black;

        .nav-link.active {
            border-color: $login-black;
            border-bottom-color: #fff;
        }
    } 

    .tab-content{
        padding-top: 20px;
        border: 1px solid $login-black;
        min-height: 360px;
    }

    .fa-info-circle{
        &:hover{
            color: $green;
        }
    }

    .package-explainer{
        padding: 1em;
        display: none;
        background-color: $green-soft;
        border-radius: 10px;
    }

    .package-holder {
        border: 1px solid $green;
        padding-top: 10px;
        border-radius: 10px;
        overflow: hidden;
        background: $green;

        .options{
            display: flex;

            div{
                flex-grow: 1;
            }
        }
    }

    .error-message{
        background: #ffcccc;
        padding: 1em 1em;            /* 0.25em 1em; snmh */
        color: #6e6565;            /* #990000; snmh */
        border-radius: 5px;
    }

    .package-group{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}

.p-0-i{
    padding: 0 !important;
}