// DASHBOARD STYLING

//variables
$color-primary : #6FCDCE;
$color-primary-dark: #60B1B1;
$color-primary-light: #86D4D2;
$softest-grey: #F5F5F5;
$soft-grey: #f0f3f5;
$dark-grey: #494949;

$green: #ECFF00;
$green-soft: #F5FF70;
$green-dark: #D6E701;

$red : #EA3525;

$white: #FFFFFF;

$text-sidebar: $white;

$text-disabled: #E5E5E5;

$top-min-height: 45px;

$box-shadow-style: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); //5px 2px 5px rgba(0, 0, 0, 0.2);

$border-wide: 2px solid #E5E5E5;

//body SNMH add line 32 background-image: url("/images/bg_blue_1");
body{
    background: $white;
    background-image: url("/images/bg_blue_1.png");
    background-size: cover;
    font-family: 'Nunito', sans-serif;

    .card{
        border: $border-wide;
        box-shadow: $box-shadow-style;
        border-radius: 16px;
        overflow: hidden;
        

        .card-header{ 
            border-bottom: 0px;
            background: none;
            font-weight: bold;
            color: $dark-grey;
            text-transform: uppercase;
            letter-spacing: 1px;
        }

        .card-icon{
            border-radius: 15px;
        }
    }
}
//header 
header.custom-header{
    border-bottom: 2px solid #E5E5E5;
    font-family: 'Nunito', sans-serif;
    padding-right: 1.0em;

    .nav-item{
        
        letter-spacing: 0.05em;
        color: #2E353B;
        font-weight: bold;
        text-transform: uppercase;

        
    }

    .nav-dropdown-items.text-left{
            text-align: left !important;

            .nav-link{
                text-align: left !important;
            }
        }

    .badge-notification{
        top: -10px !important;
        right: -10px;
        background: rgba(212,66,18,0.8);
        padding: 5px 5px 3px;
        font-weight: bolder;
        font-size: 13px;
        margin: 0 !important;
        left: unset !important;
    }
}

//main or footer - student/parent
.ml-0-i{
    margin-left: 0px !important;
}

//breadcrumb
.breadcrumb-holder{
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    margin-bottom: 0em; 

    .question-holder{
        display: flex;
        padding-bottom: 10px;
    }

    .btn-question{
        background: $green;
        font-size: 1em;
        padding: 3px 10px;
        margin-left: 1em;
        border-radius: 5px;
        color: $dark-grey;
        font-weight: bold;
    }

    .breadcrumb{
        margin: 0px;
        border-width: 0px;
    }

}
.breadcrumb{
    background: transparent;
    min-height: $top-min-height;
    border-color: $white;
    margin-bottom: 1em;

    .btn-question{
        background: $green;
        font-size: 1em;
        padding: 3px 10px;
        margin-left: 1em;
        border-radius: 5px;
        color: $dark-grey;
        font-weight: bold;
    }
    
}



//override sidebar styling
.sidebar1 {
    background: $color-primary-dark;
    color: $text-sidebar;

    .nav-title{
        min-height: $top-min-height;
        color: $text-sidebar;
    }

    .nav-dropdown.open .nav-link{
        color: $text-sidebar;
        background: $color-primary-light;
        .nav-icon{
            color: $text-sidebar;  
        }
    }

    .nav-link{
        
        color: $text-sidebar;

        .nav-icon{
            color: $text-sidebar;
        }

        &.disabled{
            color: $text-disabled;
            cursor: not-allowed !important;
            .nav-icon{
                color: $text-disabled;
            }
        }

        &.active{
            color: $text-sidebar;
            background: $color-primary-light;
            .nav-icon{
                color: $text-sidebar;  
            }
        }

        &:hover{
            color: $text-sidebar;
            background: $color-primary-light;
            .nav-icon{
                color: $text-sidebar;  
            }
        }
    }

}

.sidebar .nav-link.active .nav-icon{
    color: $green;
}

.sidebar-minimized .sidebar .nav-item:hover > .nav-link, .sidebar .nav-link:hover {
    background: #3a4248;
}

.dashboard-content{
    a{
        text-decoration: none;

        .card-icon{
            background-color: $green-dark;
            color: $white;
        }

    }

    .card-icon{
        background-color: $dark-grey;
        color: $white;
        padding: 1rem !important;
        margin-right: 1rem !important;
    }

    .course-icon {
        width: 53px;
        height: 56px;
        // border: 1px solid #F86D6B;
        // border-radius: 15px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    .primary-card {
        background-color: #EA3525; 
        color: #ffffff
    }
}

@media (min-width: 992px){
    .sidebar-minimized .sidebar .sidebar-minimizer {
        background-color: $green-dark;
    }
}

//footer
//snmh padding: 0.9rem 1rem line 256

footer.custom-footer{
    border-color: $white;
    padding: 0rem 0rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}

//others
.bg-soft-grey{
    background: $soft-grey;
}


//text
.text-primary {
    color: $dark-grey !important;
}


//top page highlight
.highlight{
    min-height: 100px;
    //background-image: url('../img/dashboard/bg-top-green.png');
    background-size: cover;
    border-radius: 16px;
    margin-bottom: 1em;
    background-color: transparent;

    .image-holder{
        padding-left: 0px; 
        margin-top: -1em;
        display: flex;
        justify-content: center;

        &.parent{
            margin-top: 0px;
            padding: 0.5em;

            img{
                width: 100%;
                height: auto;
            }
        }

        img{
            width: 150px;
            height: 150px;


        }

        
    }

    .name-details{
        min-height: 55px;
        color: $dark-grey;
        align-items: flex-end;

        &.parent{
            align-items: center;
        }

        .name{
            font-weight: bold;
            font-size: 1.5em;
            //line-height: 35px;
            text-transform: capitalize;

            &.mobile {
                font-size: 1.5em;
                text-align:center;
            }
        }

        .email{
            font-weight: bold;
            text-align: right;

            &.mobile {
                text-align:center;
            }
        }

    }

    .wordings{
        flex-grow: 1;
    }

    .level-holder{
        display: flex;
        justify-content: space-around;

        .level{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            border-radius: 10px;
            padding: 0.5em 1em;
            flex-grow: 1;
            margin: 10px 1em;
            background: rgba(0,0,0,0.05);

            h6{
                margin-bottom: 0.25em;
                color: $dark-grey;
            }

            &.learn-more {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            .star-holder{
                background-color: $dark-grey;
                border-radius: 5px;
                text-align: center;
                padding: 3px 1em;
                color: $green;
                font-weight: bold;

                .star{
                    color: #FFDC70;
                }


            }
        }

        .level2{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            border-radius: 10px;
            padding: 1.5em 1em;
            //flex-grow: 1;
            margin: 0px 0em;
            background: rgba(0,0,0,0.0);

            h6{
                margin-bottom: 0.25em;
                color: $dark-grey;
                font-weight: bold;
            }

            &.learn-more {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            .star-holder{
                //background-color: $dark-grey;
                border-radius: 5px;
                text-align: center;
                padding: 3px 1em;
                color: $white;
                font-weight: bold;

                .star{
                    color: #FFFFFF;
                }

            }
        }
    }

    &.simple-link{
        display: flex;
        align-items: center;
        justify-content: center;

        a{
            color: $dark-grey;
            font-size: 2.5em;
            text-align: center;
        }


    }
}

.card.child{
    .image-holder{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 0;

        img{
            width: 100%;
            height: auto;
            max-width: 150px;
            padding-bottom: 10px;
        }

        a{
            width: 100%;
        }

    }
    .text-holder{
        .child-name{
            color: #272727;
            font-weight: bold;
            font-size: 15px;
            text-transform: uppercase;
        }

        .child-details{
            margin-top: 10px;
            color: #272727;

            a{
            width: 100%;
            }

        }
    }
}

//course card
.course-card{
    overflow: hidden;
}

//card event
.card.event{
    cursor: pointer;

    .title{
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .date{
        border-bottom: 1px solid #E5E5E5;
        padding-bottom: 7px;
        margin-bottom: 7px;
    }

    .desc{
        img{
            width: 100% !important;
        }
    }
}

.card.event-list{
    height: 70vh;
    overflow: scroll;
}

@media (max-width: 992px){
    .nav-more{
        position: absolute;
        z-index: 10;
        background: #FFF;
        padding: 1em;
    }
}

// ---------------------
// Latest Access
// ---------------------

#user-latest-lesson{
    display: none;
    cursor: pointer;

    &:hover{
        &.card{
            border-color: $green;
        }
    }
}
.progress.lesson{
        .progress-bar {
            background-color: $green;
        }
    }

.latest-access {
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.05em;

    .lesson{
        font-size: 16px;
        margin: 5px 0;

        .name{
            font-weight: bold;
        }
    }

    .materials{
        max-height: 200px;
        overflow: scroll;
    }

    .material{
        border: 2px solid #E5E5E5;
        border-radius: 16px;
        overflow: hidden;
        margin-top: 5px;
        padding: 3px 10px;
        
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.05em;
        
        &.done{
            background-color: $green;
            border-color: $green;
        }
    }

    img{
        width: 100%;
        border-radius: 20px;
    }
}

.modal-dialog-promo {
    max-width: 820px;
    margin: 1.75rem auto;
}