$green: #ECFF00;
$green-soft: #F5FF70;
$green-dark: #D6E701;

.search-course {
    border: 1px solid #17A2B8;
}

.category-filter {
    border: 1px solid #6ad470;
}

.course-card-container {
    width: 175px; // original 230px
}

.course-card {
    margin-right: auto;
    margin-left: auto;
    border: 1px solid #ccc; 
    border-radius: 15px;

    .badge.badge-success{
        background: $green;
        color: #494949;
        margin: 8px 2px;
    }
}

.course-img {
    height: 14rem;
    overflow: hidden;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.card-title{
    .surah{
        white-space: nowrap;
    }
}
.course-material {
    margin-right: auto;
    margin-left: auto;
    background-color: #f1f1f1;
    border-radius: 4px;
    transition: 100ms ease-in all;
    cursor: pointer;

    &:hover {
        transform: scale(1.1);
    }

    .course-material-img {
        height: 10rem;
        overflow: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        border-radius: 4px;
    }
}

.course-status {
    position: absolute;
    display: flex;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    // padding-top: .25rem;
    // padding-bottom: .25rem;
    align-items: center;

    bottom: 0px; 
    width: 100%; 
    background-color: #f1f1f1; 
    border-bottom-left-radius: 15px; 
    border-bottom-right-radius: 15px;

    // min-height: 30px;
}

.course-description {
    height: 39px;
    display: block!important;
    display: -webkit-box!important;
    -webkit-line-clamp: 2;
    -moz-line-clamp: 2;
    -ms-line-clamp: 2;
    -o-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    -o-box-orient: vertical;
    box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.course-instructor {
    display: inline-block;
    line-height: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}

.course-instructor-avatar {
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.course-show-bg {
    height: 150px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;

    .btn-group {
        bottom: 10px;
        right: 10px;
    }
}

.course-show-menu {
    position: -webkit-sticky;
    position: sticky;
    top: 0;

    ul > a {
        border: none;
        padding: .25rem .5rem;

        &:hover {
            background-color: #f1f1f1;

        }
    }
}

.course-details-container {
    display: flex; 
    height: 400px;
    border: 2px solid #E5E5E5;
    border-radius: 16px;
    overflow: hidden;
    
    .course-cover-img {
        height: 400px; 
    }

    .course-canvas {
        flex: 1; 
        display: flex;
        height: 400px; 
        overflow-y: scroll; 
        background-color: #ececec;
    }

    .course-frame {
        flex: 1; 
        height: 400px; 
        background-color: #000;
    }

    .course-content {
        width: 300px; 
        display: flex; 
        flex-direction: column;
        border: 1px solid #ececec;
        background: #f6f6f6;

    }

    .list-group-item {
        cursor: pointer;
        margin: 0;
        border-radius: 0;
        border-top: 0;

        &:first-of-type {
            border-top: 1px solid rgba(0, 0, 0, 0.125);
        }

        &:hover {
            background-color: #e4e7ea !important;
        }
        
        &.collapse {
            background-color: #f0f3f5 !important;
        }
    }
}

.btn-course-content {
        background: $green;
        border-radius: 16px;
        padding: 10px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: bold;
        margin-bottom: 2px;
        cursor:pointer;
        display: none;
        color: #333333;
    }
//MOBILE RESPONSIVE

@media (max-width: 767px) {
    .btn-course-content {
        display: block;
    }
    .course-details-container {
        position: relative;

        .course-content {
            position: absolute;
            width: 100%;
        }
    }
}